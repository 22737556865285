import React, { useEffect, useState } from "react";
import "./Dropdown.scss";
import { Box } from "@mui/material";
import useComponentVisible from "../../../../hooks/useComponentVisible";

const MultiDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  placeholder,
  disabled,
  show,
  excludeFirstOption = false,
    shortenSelectedLabel = true
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
    }
  }, [isComponentVisible]);

  const chosenOption = (option) => {
    setSelectedOptions(option);
    // setIsOpen(false);
  };

  const openDropdown = () => {
    if (disabled === true) {
      return;
    }
    setIsComponentVisible(true);
    setIsOpen(true);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  function shortenString(string, maxLength = 20){
    // string = `${string} ${string} ${string}  `;
    // if(shortenSelectedLabel && string?.length > maxLength){
    //     return `${string.substring(0, 20).trim()}...`;
    // }
    return string;
  }

  function isSelected(option){
    return !!selectedOptions?.find(item => item?.id === option?.id);
  }

  return (
    <div className="Dropdown" ref={ref} style={{height:'fit-content', minHeight:'40px'}}>
      <Box
        className={"dropdownLabel"}
      >
        {label}
      </Box>
      <Box className={"dropdownMain"} style={{height:'fit-content', minHeight:'40px'}}>
        {isOpen ? (
          <Box className={"dropdownOpen"}  style={{height:'fit-content', minHeight:'40px'}}>
            <Box className={"dropdownPlaceholder"} style={{height:'fit-content', minHeight:'40px'}}>
              {" "}{placeholder}{" "}
            </Box>
            <img
              src={"/assets/Arrow_Grey.svg"}
              className={"dropdownArrow dropdownArrowOpen "}
              alt="dropdownArrow"
            />

            {show && (
              <div className="showDropdownListTitle" >
                <h3>Clinic</h3>
                <p onClick={() => chosenOption(options[0])}>
                  {options[0]?.name}
                </p>
                <h3>Practitioners</h3>
              </div>
            )}
            {options?.map((option, index) => {
              if (excludeFirstOption && index === 0) {
                return null; // Skip rendering the first option
              }
              return (
                <Box
                  className={"dropdownOption "}
                  style={{
                    padding:'10px',
                    minHeight:'fit-content',
                    color: isSelected(option) ? '#61849d' : 'inherit',
                    borderBottom: (options?.length !== index + 1) ? '1px solid #e4e4e4' : 'none',
                  }}
                  key={index}
                  onClick={() => chosenOption(option)}
                >
                  {" "}
                  {shortenString(option?.name, 30)}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box
            className={"dropdownClosed "}
            style={{height:'fit-content', minHeight:'40px'}}
            onClick={() => openDropdown()}
          >
                  <Box className={"selectedOption"} style={{marginBottom:'10px'}}>
                    {
                      (selectedOptions && selectedOptions?.length > 0)  ?
                          selectedOptions?.map((option,index) => {
                            return <div
                                key={index}
                                style={{
                                  marginRight:'50px',
                                  padding:'5px',
                                  borderBottom: (selectedOptions?.length !== index + 1) ? '1px solid #e4e4e4' : 'none',
                                }}
                            >
                              <div> {shortenString(option.name)}</div>
                            </div>
                          })
                          :
                          placeholder
                    }
                  </Box>


            <img
              src={"/assets/Arrow_Grey.svg"}
              className={"dropdownArrow"}
              alt="dropdownArrow"
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MultiDropdown;
