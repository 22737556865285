import React from "react";
import i18n from "i18next";

export default function LeaveGoogleReviewCard({showLink = false, reviewLink}) {
    return <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '570px',
            borderRadius: "10px",
            backgroundColor: "rgba(97, 132, 157, 0.15)",
            padding: '20px 40px',
        }}>
            <h4 style={{
                color: "rgba(31, 31, 31, 1)",
                fontFamily: "HelveticaNeueMedium",
                fontSize: "16px",
                fontWeight: "500",
                fontStyle: "Medium",
                letterSpacing: "0px",
                textAlign: "center",
                lineHeight: "19px"
            }}>
                {showLink ? i18n.t("leaveGoogleReviewLinkTitle") : i18n.t('leaveGoogleReviewTitle')}
            </h4>
            <p style={{
                "color": "rgba(73, 73, 73, 1)",
                fontFamily: "HelveticaNeue",
                fontSize: "16px",
                fontWeight: "400",
                fontStyle: "Regular",
                letterSpacing: "0px",
                textAlign: "center",
                lineHeight: "21px"
            }}>
                {showLink ? i18n.t("leaveGoogleReviewLinkDesc") : i18n.t('leaveGoogleReviewDesc')}
            </p>
            {
                showLink &&
                <a
                    target="_blank"
                    href={reviewLink ?? '#'}
                    style={{
                        padding: "6px 21px",
                        color: "white",
                        textDecoration: "none",
                        textTransform: "uppercase",
                        borderRadius: "23px",
                        boxShadow: "0px 2px 4px rgba(217, 217, 217, 1)",
                        backgroundColor: "rgba(97, 132, 157, 1)"
                    }}
                >
                    {i18n.t('leaveGoogleReviewTitle')}
                </a>
            }
        </div>

    </div>
}