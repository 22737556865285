import React, {useEffect, useState} from 'react';
import './ThankYouSurvey.scss';
import {useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {deletePreviousSurveyData, deleteSurveyData, updateSurveyPage} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";
import LeaveGoogleReviewCard from "../../../../components/LeaveGoogleReviewCard";


const ThankYouSurvey = ({showGlobalSnackbar, survey, i18n, app}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const dispatch = useDispatch();



    const [surveyData, setSurveyData] = useState(null);
    const [showGoogleReviewCard, setShowGoogleReviewCard] = useState(false);
    const [reviewLink, setReviewLink] = useState()

    useEffect(() => {
        if(survey?.data){
            if(survey.settings?.clinic?.googleLink){
                console.log('survey.settings?.clinic?.googleLink',survey.settings?.clinic?.googleLink);
                setReviewLink(survey.settings?.clinic?.googleLink?.startsWith("http")
                    ? survey?.settings?.clinic?.googleLink
                    : `https://${survey?.settings?.clinic?.googleLink}`);
            }
            setSurveyData(survey?.data);
        }
    }, [survey]);

    useEffect(() => {
        if(surveyData){
            if(surveyData?.healthChanges?.find(item => item.name === "openEnded5")?.concernRate == 5 && survey.settings?.clinic?.googleLink){
                setShowGoogleReviewCard(true);
            }

            dispatch(deleteSurveyData());
            dispatch(deletePreviousSurveyData());
        }
    },[surveyData]);


    useEffect(() => {
        window.scrollTo(0, 0);
        // dispatch(deleteSurveyData());
        // dispatch(deletePreviousSurveyData());
        dispatch(updateSurveyPage(5));
    }, []);


    const nextPage = () => {
      //
    }


    return (
        <div className="FirstScreenSurvey">

            <SurveyHero
                step={i18n.t("bye")}
                headline={survey?.settings?.thankHeadline}
                text={survey?.settings?.thankText}
                videoLink={survey?.settings?.thankVideo}
                buttonText={""}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
                reviewCard={
                    showGoogleReviewCard ? <LeaveGoogleReviewCard showLink={true} reviewLink={reviewLink}/> : null
                }
            />

        </div>
    );
};

export default ThankYouSurvey;
