import React, {useEffect, useState} from "react";
import "./HealthConcernText.scss";
import TextInput from "../../Comon/TextInput/TextInput";
import HealthConcernRate from "../HealthConcernRate/HealthConcernRate";
import {updateSurveyData} from "../../../../redux/actions/survey";
import {Box} from "@mui/material";
import SurveyCheckboxOption from "../SurveyCheckboxOption/SurveyCheckboxOption";

const HealthConcernText = ({
                               themeColor,
                               topText,
                               headline,
                               text,
                               mandatory,
                               concernText,
                               setConcernText,
                               concernRate,
                               setConcernRate,
                               hasTextInput,
                               i18n,
                               leftOption,
                               rightOption,
                               options,
                               setOptions,
                               id,
                               hasRate = true,
                               hasSpecificRate,
                               singleSelect = false,
                               characterLimit = null,
                               separatedInParts = null,
                           }) => {

    const [parts, setParts] = useState([]);
    const updateOptions = (index, value, type) => {
        if (type === "text") {
            options[index].text = value;
        } else {
            options[index].selected = value;
            if (value === true && singleSelect) {
                options.forEach((o, optionIndex) => {
                    if (optionIndex !== index) {
                        o.selected = false;
                    }
                });
            }
        }

        setOptions(options);
    };

    useEffect(() => {
        if (separatedInParts) {
          setParts(Array(separatedInParts).fill(''));

          if (concernText.length > 0) {
            const partsArr = concernText.split(",");
            if (partsArr[0]) {
              parts[0] = partsArr[0];
            }
            if (partsArr[1]) {
              parts[1] = partsArr[1];
            }
            if (partsArr[2]) {
              parts[2] = partsArr[2];
            }

            setParts([...parts]);
          }
        }
    }, [separatedInParts]);


    const updatePart = (value, index) => {
      parts[index] = value;
      setParts([...parts]);

      let newConcernText = "";

      if (parts[0].length > 0) {
        newConcernText = newConcernText + parts[0];
      }

      if (parts[1].length > 0) {
        newConcernText = newConcernText + ", " + parts[1];
      }


      if (parts[2].length > 0) {
        newConcernText = newConcernText + ", " + parts[2];
      }

      setConcernText(newConcernText);



    }

    return (
        <div className="HealthConcernText" id={id}>
            <div className={"healthTopHeadline"} style={{borderColor: themeColor}}>
                {topText}
            </div>
            <div className={"healthHeadline"}>{headline}</div>
            <div className={"healthDescription"}>
                {text} {mandatory ? i18n.t("mandatoryHealthConcern") : null}
            </div>
            {hasTextInput === true ? (
                    separatedInParts && parts ?
                        parts.map((part, index) => {
                            return <div className={"healthTextContainer"}>
                                <TextInput
                                    maxlength={characterLimit}
                                    rows={3}
                                    label={""}
                                    mode={"grey"}
                                    placeholder={i18n.t("personNumber") + (index + 1)}
                                    type={"textarea"}
                                    specialClass={"smallTextSurveyInput"}
                                    setValue={(value) => updatePart(value, index)}
                                    value={part}
                                    errorMessage={
                                        characterLimit && part.length > (characterLimit - 1)
                                            ? characterLimit + " " + i18n.t("characterLimitations")
                                            : null
                                    }
                                />

                            </div>
                        })
                        :
                        <div className={"healthTextContainer"}>
                            <TextInput
                                maxlength={characterLimit}
                                rows={3}
                                label={""}
                                mode={"grey"}
                                placeholder={i18n.t("typeHere")}
                                type={"textarea"}
                                setValue={setConcernText}
                                value={concernText}
                                errorMessage={
                                    characterLimit && concernText.length > (characterLimit - 1)
                                        ? characterLimit + " " + i18n.t("characterLimitations")
                                        : null
                                }
                            />
                        </div>
                ) :
                (
                    <div className={"noTextSeparator"}/>
                )
            }
            {options == null && hasRate ? (
                <div
                    className={
                        hasSpecificRate
                            ? "healthConcernSpecificRatesContainer"
                            : "healthConcernRatesContainer"
                    }
                >
                    <HealthConcernRate
                        rate={concernRate}
                        hasSpecificRate={hasSpecificRate}
                        themeColor={themeColor}
                        i18n={i18n}
                        leftOption={leftOption}
                        rightOption={rightOption}
                        setRate={(value) => setConcernRate(value)}
                    />
                </div>
            ) : null}
            {options
                ? options
                    .sort((a, b) => a.id - b.id)
                    .map((option, index) => {
                        return (
                            <div className={"optionContainer"} key={index}>
                                <SurveyCheckboxOption
                                    text={option.text}
                                    setText={(value) => updateOptions(index, value, "text")}
                                    selected={option.selected}
                                    isOther={option.isOther}
                                    setSelected={(value) =>
                                        updateOptions(index, value, "selected")
                                    }
                                />
                            </div>
                        );
                    })
                :
                null
            }
        </div>
    )
        ;
};

export default HealthConcernText;
